<script>
  import Caption from "./common/Caption.svelte";
</script>

<style lang="scss">
  section {
    position: relative;
    height: calc(100vh - 7rem);
    background-image: url("/images/cathedral.jpg");
    background-size: cover;
    background-position: center;
  }
</style>

<section>
  <Caption href="https://www.instagram.com/p/B8PFXV3pXnq/">
    Lincoln Cathedral, Kodak TRI-X 400 +2, Lawrence Thorpe 2020
  </Caption>
</section>
